import React, { useState, useEffect } from "react";

const DropboxAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const CLIENT_ID = "1gd6un1lnloasm1";
  const REDIRECT_URI = "https://commonthreadco.ai/dropbox-auth"; // Or your app's URL

  useEffect(() => {
    // Check for access token in URL after redirect
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const token = params.get("access_token");
    console.log(token);

    if (token) {
      setHasToken(true);
      // Post the token to the background
      fetch(
        "https://powerful-refuge-95735-e636965d892f.herokuapp.com/api/dropbox-auth/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ accessToken: token }),
        }
      )
        .then(() => {
          // Close the window immediately after sending the request
          window.close();
        })
        .catch((error) => {
          console.error("Error:", error);
          // Close the window even if there's an error
          window.close();
        });
    }
  }, []);

  const handleAuth = () => {
    const authUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${REDIRECT_URI}`;
    window.location.href = authUrl;
  };

  const handleLogout = () => {
    fetch("/dropbox-auth", {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {hasToken ? (
        <div className="text-center">
          <h1 className="text-6xl font-bold text-indigo-600 mb-8">
            CLOSE THIS WINDOW
          </h1>
        </div>
      ) : (
        <div className="text-center">
          {isAuthenticated ? (
            <div>
              <p className="mb-4 text-lg">
                You are authenticated with Dropbox!
              </p>
              <button
                onClick={handleLogout}
                className="px-6 py-3 text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300"
              >
                Logout
              </button>
            </div>
          ) : (
            <button
              onClick={handleAuth}
              className="px-6 py-3 text-white bg-[#0061FF] rounded-lg hover:bg-[#0052D9] focus:outline-none focus:ring-2 focus:ring-[#0061FF] focus:ring-opacity-50 transition duration-300 flex items-center justify-center"
            >
              <svg
                className="w-6 h-6 mr-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 2L6 7l6 5-6 5 6 5 6-5-6-5 6-5-6-5zm0 10l-6 5 6 5 6-5-6-5z" />
              </svg>
              Authenticate with Dropbox
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DropboxAuth;
