import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  FaDownload,
  FaUpload,
  FaStar,
  FaTag,
  FaSearch,
  FaTimes,
} from "react-icons/fa";
import { motion } from "framer-motion";

const dev = false;
let baseUrl;

if (dev) {
  baseUrl = "http://localhost:8000";
} else {
  baseUrl = "https://powerful-refuge-95735-e636965d892f.herokuapp.com";
}

const ImageGeneratorApp = () => {
  const [prompt, setPrompt] = useState("");
  const [images, setImages] = useState([]);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [numOutputs, setNumOutputs] = useState(1);
  const [placeholders, setPlaceholders] = useState([]);
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [cameraStyle, setCameraStyle] = useState("Canon 5d Mark IV");
  const [effects, setEffects] = useState([]);
  const [selectedEffect, setSelectedEffect] = useState({
    label: "None",
    id: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loras, setLoras] = useState([]);
  const [selectedLora, setSelectedLora] = useState({
    label: "POLO",
    trigger_word: "POLO",
    id: "",
  });
  const [showFavouritesOnly, setShowFavouritesOnly] = useState(false);
  const [labelInput, setLabelInput] = useState("");
  const [showLabelInput, setShowLabelInput] = useState(false);
  const [labelingImageId, setLabelingImageId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [newImageIds, setNewImageIds] = useState([]);
  const [error, setError] = useState(null);

  const { id } = useParams();
  const observer = useRef();

  const lastImageElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  useEffect(() => {
    if (isGenerating) {
      setPlaceholders(Array(numOutputs).fill(null));
    } else {
      setPlaceholders([]);
    }
  }, [isGenerating, numOutputs]);

  useEffect(() => {
    const fetchSavedImages = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/get-ai-images?id=${encodeURIComponent(id)}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch saved images");
        }
        const data = await response.json();
        if (data.error) {
          throw new Error(data.error);
        }
        console.log(data);
        setImages(data.images);
        setLoras(data.loras);
        setEffects([{ label: "None", id: "" }, ...data.effects]);
        setPrompt(data.ai_default_prompt);
        if (data.loras.length > 0) {
          setSelectedLora({
            label: data.loras[0].label,
            trigger_word: data.loras[0].trigger_word,
            id: data.loras[0].id,
          });
        }
      } catch (error) {
        console.error("Error fetching saved images:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSavedImages();
  }, [id]);

  useEffect(() => {
    const filteredImages = images
      .filter((image) => !showFavouritesOnly || image.is_favourite)
      .filter(
        (image) =>
          (searchTerm === "" ||
            image.label?.toLowerCase().includes(searchTerm.toLowerCase())) &&
          (selectedTag === "" || image.label === selectedTag)
      );

    setDisplayedImages(filteredImages.slice(0, page * 100));
    setHasMore(filteredImages.length > page * 100);
  }, [images, showFavouritesOnly, searchTerm, selectedTag, page]);

  const handleGenerateImages = async () => {
    setIsGenerating(true);
    setError(null);
    try {
      const formData = new FormData();
      const processedPrompt = prompt.replace(
        /\[PRODUCT\]/g,
        selectedLora.trigger_word
      );
      formData.append("prompt", processedPrompt);
      formData.append("num_outputs", numOutputs);
      formData.append("id", id);
      formData.append("aspect_ratio", aspectRatio);
      formData.append("camera_style", cameraStyle);
      formData.append("lora_id", selectedLora.id);
      if (selectedEffect.id) {
        formData.append("effect_id", selectedEffect.id);
      }

      if (uploadedImage) {
        const response = await fetch(uploadedImage);
        const blob = await response.blob();
        formData.append("image", blob, "uploaded_image.png");
      }

      const response = await fetch(`${baseUrl}/api/generate-image/`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to start image generation");
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }

      const { task_id } = data;

      // Polling function to check task status
      const pollTaskStatus = async (taskId) => {
        try {
          const statusResponse = await fetch(
            `${baseUrl}/api/check-image-status/${taskId}`,
            { method: "GET" }
          );
          if (!statusResponse.ok) {
            throw new Error("Failed to check image status");
          }
          const statusData = await statusResponse.json();
          if (statusData.error) {
            throw new Error("Error checking image status");
          }

          if (statusData.status === "completed") {
            if (statusData.images.length > 0) {
              setImages((prevImages) => [...statusData.images, ...prevImages]);
              setNewImageIds(statusData.images.map((img) => img.pk));
            }
            setIsGenerating(false);
          } else if (statusData.status === "processing") {
            // Poll again after a short delay
            setTimeout(() => pollTaskStatus(taskId), 3000);
          } else {
            throw new Error("Image generation failed");
          }
        } catch (error) {
          console.error("Error polling task status:", error);
          setError(error.message);
          setIsGenerating(false);
        }
      };

      // Start polling
      pollTaskStatus(task_id);
    } catch (error) {
      console.error("Error generating images:", error);
      setError(error.message);
      setIsGenerating(false);
    }
  };

  const handleEnhanceFace = async (imageUrl) => {
    setIsGenerating(true);
    setError(null);
    try {
      const processedPrompt = prompt.replace(
        /\[PRODUCT\]/g,
        selectedLora.trigger_word
      );
      const response = await fetch(
        `${baseUrl}/api/generate-image/?prompt=${encodeURIComponent(
          processedPrompt
        )}&num_outputs=${numOutputs}&id=${encodeURIComponent(
          id
        )}&aspect_ratio=${encodeURIComponent(
          aspectRatio
        )}&camera_style=${encodeURIComponent(
          cameraStyle
        )}&enhance_face=true&image_url=${encodeURIComponent(imageUrl)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to start image generation");
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }

      const { task_id } = data;

      // Polling function to check task status
      const pollTaskStatus = async (taskId) => {
        try {
          const statusResponse = await fetch(
            `${baseUrl}/api/check-image-status/${taskId}`,
            { method: "GET" }
          );
          if (!statusResponse.ok) {
            throw new Error("Failed to check image status");
          }
          const statusData = await statusResponse.json();
          if (statusData.error) {
            throw new Error("Error checking image status");
          }
          if (statusData.status === "completed") {
            setImages((prevImages) => [...statusData.images, ...prevImages]);
            setIsGenerating(false);
          } else if (statusData.status === "processing") {
            // Poll again after a short delay
            setTimeout(() => pollTaskStatus(taskId), 3000);
          } else {
            throw new Error("Image generation failed");
          }
        } catch (error) {
          console.error("Error polling task status:", error);
          setError(error.message);
          setIsGenerating(false);
        }
      };

      // Start polling
      pollTaskStatus(task_id);
    } catch (error) {
      console.error("Error generating images:", error);
      setError(error.message);
      setIsGenerating(false);
    }
  };

  const getProxiedUrl = (url) =>
    `https://proxy-image-fa8914807e51.herokuapp.com/api/proxy-image?url=${encodeURIComponent(
      url
    )}`;

  const handleDownloadImage = async (imageUrl) => {
    try {
      // Fetch the highest resolution version of the image available
      const response = await fetch(getProxiedUrl(imageUrl));

      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "generated-image.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
      setError(error.message);
    }
  };

  const handleFavoriteImage = async (pk) => {
    try {
      const response = await fetch(`${baseUrl}/api/favourite-image/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pk: pk }),
      });

      if (!response.ok) {
        throw new Error("Failed to favorite image");
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }

      // Update the local state to reflect the change
      setImages((prevImages) =>
        prevImages.map((img) =>
          img.pk === pk ? { ...img, is_favourite: !img.is_favourite } : img
        )
      );
    } catch (error) {
      console.error("Error favoriting image:", error);
      setError(error.message);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedImage(e.target.result);
        setPrompt("");
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveUploadedImage = () => {
    setUploadedImage(null);
  };

  const insertProductPill = () => {
    const textarea = document.getElementById("promptTextarea");
    const cursorPosition = textarea.selectionStart;
    const textBeforeCursor = prompt.slice(0, cursorPosition);
    const textAfterCursor = prompt.slice(cursorPosition);
    const newPrompt = `${textBeforeCursor}[PRODUCT]${textAfterCursor}`;
    setPrompt(newPrompt);
  };

  const handleLabelImage = async (pk) => {
    try {
      const response = await fetch(`${baseUrl}/api/label-image/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pk: pk, label: labelInput }),
      });

      if (!response.ok) {
        throw new Error("Failed to label image");
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }

      // Update the local state to reflect the change
      setImages((prevImages) =>
        prevImages.map((img) =>
          img.pk === pk ? { ...img, label: labelInput } : img
        )
      );

      // Reset label input and hide the input field
      setLabelInput("");
      setShowLabelInput(false);
      setLabelingImageId(null);
    } catch (error) {
      console.error("Error labeling image:", error);
      setError(error.message);
    }
  };

  const uniqueTags = [
    ...new Set(images.map((image) => image.label).filter(Boolean)),
  ];

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gradient-to-br from-purple-50 to-indigo-100">
      {/* Toolbar */}
      <div className="w-full md:w-1/4 lg:w-1/5 bg-white p-4 md:p-6 shadow-lg">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-indigo-700">
          Generate Images
        </h2>
        <div className="space-y-3 md:space-y-4">
          <div className="relative">
            <textarea
              id="promptTextarea"
              placeholder="Enter your magical prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent resize-vertical"
              rows={4}
              style={{ minHeight: "80px", maxHeight: "200px" }}
            />
            <button
              onClick={insertProductPill}
              className="mt-2 bg-indigo-200 text-indigo-700 px-3 py-1 rounded-full text-sm hover:bg-indigo-300 transition-colors duration-300"
              title={`Insert product placeholder`}
            >
              Product
            </button>
          </div>
          <div className="flex flex-col">
            <label htmlFor="numOutputs" className="text-sm text-gray-600 mb-1">
              Number of Images: {numOutputs}
            </label>
            <input
              type="range"
              id="numOutputs"
              min="1"
              max="4"
              value={numOutputs}
              onChange={(e) => setNumOutputs(parseInt(e.target.value))}
              className="w-full"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="aspectRatio" className="text-sm text-gray-600 mb-1">
              Aspect Ratio:
            </label>
            <select
              id="aspectRatio"
              value={aspectRatio}
              onChange={(e) => setAspectRatio(e.target.value)}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="1:1">1:1 (Square)</option>
              <option value="4:3">4:3 (Landscape)</option>
              <option value="16:9">16:9 (Landscape)</option>
              <option value="9:16">9:16 (Portrait)</option>
              <option value="3:4">3:4 (Portrait)</option>
            </select>
          </div>
          {/* <div className="flex flex-col">
            <label htmlFor="cameraStyle" className="text-sm text-gray-600 mb-1">
              Camera Style:
            </label>
            <select
              id="cameraStyle"
              value={cameraStyle}
              onChange={(e) => setCameraStyle(e.target.value)}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="Fuifilm X100v">Fuifilm X100v</option>
              <option value="Kodak">Kodak</option>
              <option value="Canon 5d Mark IV">Canon 5d Mark IV</option>
              <option value="Black and White">Black and White</option>
            </select>
          </div> */}
          <div className="flex flex-col">
            <label htmlFor="effects" className="text-sm text-gray-600 mb-1">
              Effects:
            </label>
            <select
              id="effects"
              value={selectedEffect.label}
              onChange={(e) => {
                const selected = effects.find(
                  (effect) => effect.label === e.target.value
                );
                setSelectedEffect({
                  label: selected.label,
                  id: selected.id,
                });
              }}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              {effects.map((effect, index) => (
                <option key={index} value={effect.label}>
                  {effect.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="loras" className="text-sm text-gray-600 mb-1">
              Products:
            </label>
            <select
              id="loras"
              value={selectedLora.label}
              onChange={(e) => {
                const selected = loras.find(
                  (lora) => lora.label === e.target.value
                );
                setSelectedLora({
                  label: selected.label,
                  trigger_word: selected.trigger_word,
                  id: selected.id,
                });
              }}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              {loras.map((lora, index) => (
                <option key={index} value={lora.label}>
                  {lora.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="imageUpload" className="text-sm text-gray-600 mb-1">
              Upload Image:
            </label>
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
            <label
              htmlFor="imageUpload"
              className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center cursor-pointer"
            >
              <FaUpload className="mr-2" />
              Upload Image
            </label>
          </div>
          {uploadedImage && (
            <div className="mt-2 relative">
              <img
                src={uploadedImage}
                alt="Uploaded"
                className="w-full h-auto rounded-md"
              />
              <button
                onClick={handleRemoveUploadedImage}
                className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors duration-300"
              >
                <FaTimes />
              </button>
            </div>
          )}
          {selectedImage && (
            <div className="flex flex-col">
              <button
                onClick={() => handleEnhanceFace(selectedImage)}
                className="w-full bg-green-600 text-white p-2 rounded-md hover:bg-green-700 transition-colors duration-300 mt-2 flex items-center justify-center"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                Enhance Face
              </button>
            </div>
          )}
          <button
            onClick={handleGenerateImages}
            className={`w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center ${
              isGenerating ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isGenerating}
          >
            {isGenerating ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Generating...
              </>
            ) : (
              <>
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                </svg>
                Generate Images (~3 minutes)
              </>
            )}
          </button>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="showFavourites"
              checked={showFavouritesOnly}
              onChange={(e) => setShowFavouritesOnly(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="showFavourites" className="text-sm text-gray-600">
              Show Favourites Only
            </label>
          </div>
          <div className="flex flex-col">
            <label htmlFor="searchTerm" className="text-sm text-gray-600 mb-1">
              Search by Tag:
            </label>
            <div className="flex items-center">
              <input
                type="text"
                id="searchTerm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Enter tag to search"
                className="flex-grow p-2 border border-indigo-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              />
              <button
                onClick={() => setSearchTerm("")}
                className="bg-indigo-600 text-white p-2 rounded-r-md hover:bg-indigo-700 transition-colors duration-300"
              >
                <FaSearch />
              </button>
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="tagFilter" className="text-sm text-gray-600 mb-1">
              Filter by Tag:
            </label>
            <select
              id="tagFilter"
              value={selectedTag}
              onChange={(e) => setSelectedTag(e.target.value)}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="">All Tags</option>
              {uniqueTags.map((tag, index) => (
                <option key={index} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Image Grid */}
      <div className="flex-1 p-4 md:p-8 overflow-auto">
        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {isLoading ? (
            <div className="col-span-full flex justify-center items-center">
              <svg
                className="animate-spin h-10 w-10 text-indigo-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            <>
              {isGenerating &&
                placeholders.map((_, index) => (
                  <div
                    key={`placeholder-${index}`}
                    className="w-full h-40 sm:h-48 md:h-56 lg:h-64 bg-black bg-opacity-10 rounded-lg shadow-md flex items-center justify-center"
                  >
                    <svg
                      className="animate-spin h-8 w-8 text-indigo-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                ))}
              {displayedImages.map((image, index) => {
                const isLastElement = index === displayedImages.length - 1;
                const isNewImage = newImageIds.includes(image.pk);
                return (
                  <motion.div
                    key={`image-${image.pk}`}
                    ref={isLastElement ? lastImageElementRef : null}
                    className="transform transition-all duration-300 hover:scale-105 relative"
                    initial={isNewImage ? { opacity: 0, scale: 0.9 } : {}}
                    animate={isNewImage ? { opacity: 1, scale: 1 } : {}}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={image.url}
                      alt={`Generated ${index + 1}`}
                      className={`w-full h-auto rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ${
                        isNewImage ? "border-4 border-indigo-500" : ""
                      }`}
                      onClick={() => {
                        console.log(image);
                        setPrompt(image.prompt);
                        setCameraStyle(image.camera_style);
                        setAspectRatio(image.aspect_ratio);
                        setSelectedImage(image.url);
                        // Match the lora_id to the loras in the dropdown
                        const matchedLora = loras.find(
                          (lora) => lora.id === image.lora_id
                        );
                        if (matchedLora) {
                          setSelectedLora({
                            label: matchedLora.label,
                            trigger_word: matchedLora.trigger_word,
                            id: matchedLora.id,
                          });
                        }
                        const matchedEffect = effects.find(
                          (effect) => effect.id === image.effect_id
                        );
                        if (matchedEffect) {
                          setSelectedEffect({
                            label: matchedEffect.label,
                            id: matchedEffect.id,
                          });
                        } else {
                          setSelectedEffect({
                            label: "None",
                            id: "",
                          });
                        }
                      }}
                    />
                    <div className="absolute top-2 right-2 flex space-x-2">
                      <button
                        onClick={() => handleDownloadImage(image.url)}
                        className="bg-white bg-opacity-75 p-2 rounded-full hover:bg-opacity-100 transition-all duration-300"
                      >
                        <FaDownload className="text-indigo-600" />
                      </button>
                      <button
                        onClick={() => handleFavoriteImage(image.pk)}
                        className="bg-white bg-opacity-75 p-2 rounded-full hover:bg-opacity-100 transition-all duration-300"
                      >
                        <FaStar
                          className={
                            image.is_favourite
                              ? "text-yellow-500"
                              : "text-gray-400"
                          }
                        />
                      </button>
                      <button
                        onClick={() => {
                          setShowLabelInput(true);
                          setLabelingImageId(image.pk);
                        }}
                        className="bg-white bg-opacity-75 p-2 rounded-full hover:bg-opacity-100 transition-all duration-300"
                      >
                        <FaTag className="text-indigo-600" />
                      </button>
                    </div>
                    {image.label && (
                      <div className="absolute bottom-2 left-2 bg-white bg-opacity-75 px-2 py-1 rounded-full text-sm">
                        {image.label}
                      </div>
                    )}
                    {showLabelInput && labelingImageId === image.pk && (
                      <div className="absolute bottom-2 left-2 right-2 flex">
                        <input
                          type="text"
                          value={labelInput}
                          onChange={(e) => setLabelInput(e.target.value)}
                          placeholder="Enter label"
                          className="flex-grow p-1 text-sm border border-indigo-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                        />
                        <button
                          onClick={() => handleLabelImage(image.pk)}
                          className="bg-indigo-600 text-white px-2 py-1 rounded-r-md hover:bg-indigo-700 transition-colors duration-300"
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </motion.div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageGeneratorApp;
